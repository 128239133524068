/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
interface Trabalhador {
  id: number;
  trabalhador: string;
}
const ChefesSAFP = () => {
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  const [listaFaltas, setFaltas] = useState<Falta[]>([]);

  const jwtToken = localStorage.getItem("jwtToken");
  const xsrfToken = localStorage.getItem("XSRF-TOKEN");
  useEffect(() => {
    fetchUpcomingEventsData2();
    fetchUpcomingEventsData9();
  }, []);

  const [chefes, setChefes] = useState<Trabalhador[]>([]);
  const [selectedChefe, setSelectedChefe] = useState<Trabalhador | null>(null);
  const [selectedChefe2, setSelectedChefe2] = useState<Trabalhador | null>(
    null
  );

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  async function fetchUpcomingEventsData9() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getChefes",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChefes(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );

        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [trabalhadores, setTrabalhadores] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador, setSelectedTrabalhador] =
    useState<Trabalhador | null>(null);

  const fetchUpcomingEventsData2 = async () => {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadores",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();

        setTrabalhadores(
          data.map((dado: { ID: number; nome: string }) => ({
            id: dado.ID,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("Could not getTrabalhadores", error);
    }
  };

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);
  const [editHorasDialog, setEditHorasDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    document.body.classList.add("no-scroll");
    setSelectedTrabalhador(null);
    setErrorMessage("");
    setSelectedChefe(null);
    setSelectedChefe2(null);
    setSelectedPermissao("0");
  };

  const openDiasCompletos = () => {
    handleVacationButtonClick();

    clean();

    setHeader("Chefe");
    setIcon("pi-users");
    setEditDiasCompletosDialog(true);
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    setErrorMessage("");
    handleVacationButtonClick();

    setSelectedRow(rowData.id_chefe);

    const chefe = {
      id: rowData.id, // Converte para inteiro
      trabalhador: rowData.nome,
    };
    setSelectedChefe(chefe);

    const chefe2 = {
      id: rowData.chefe_delegado, // Converte para inteiro
      trabalhador: rowData.nome_chefe_delegado,
    };
    setSelectedChefe2(chefe2);

    setSelectedPermissao(rowData.safp);
    setSelectedSuperChefe(rowData.superChefe);
    setIcon("pi-pencil");
    setHeader("Editar Chefe");
    setEditHorasDialog(true);
  };

  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const hideEditHoras = () => {
    setEditHorasDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const [IsAddChefe, setIsAddChefe] = useState(false);

  const addChefe = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddChefe) return;
    setIsAddChefe(true);

    if (selectedTrabalhador == null) {
      setIsAddChefe(false);
      setErrorMessage("Selecione um trabalhador");
      return;
    }

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/addChefe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
          "x-xsrf-token": csrfToken, // Add CSRF token in the request header
        },
        credentials: "include",
        body: JSON.stringify({
          id_utilizador: selectedTrabalhador.id,
          safp: selectedPermissao,
        }),
      });

      if (response.ok) {
        fetchUpcomingEventsData9();
        showNiceMessage("Chefe adicionado com sucesso");
        hideEditDiasCompletos();
      } else {
        setErrorMessage(
          "Erro ao adicionar chefe. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao adicionar chefe. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsAddChefe(false);
    }
  };
  const [IsUpdate, setIsUpdate] = useState(false);

  const updateChefe = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsUpdate) return;
    setIsUpdate(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/updateChefe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            id_chefe: selectedRow,
            id_utilizador: selectedChefe?.id,
            safp: selectedPermissao,
            chefe_atual: selectedChefe2?.id,
            superChefe: selectedSuperChefe,
          }),
        }
      );

      if (response.ok) {
        fetchUpcomingEventsData9();
        showNiceMessage("Chefe atualizado com sucesso");
        hideEditHoras();
      } else {
        setErrorMessage(
          "Erro ao adicionar chefe. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao adicionar chefe. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsUpdate(false);
    }
  };

  const [IsDeleting, setIsDeleting] = useState(false);

  const deleteChefe = async (id_chefe) => {
    if (!csrfToken) return;
    if (!token) return;

    if (
      id_chefe == null ||
      id_chefe == undefined ||
      selectedUser == null ||
      selectedUser == undefined
    )
      return;

    if (IsDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/deleteChefe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id_chefe, id_utilizador: selectedUser }),
        }
      );

      if (response.ok) showNiceMessage2("Chefe eliminado com sucesso");

      fetchUpcomingEventsData9();
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label="Adicionar Chefe"
            icon="pi pi-plus"
            rounded
            className=" mr-2"
            onClick={openDiasCompletos}
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const editarFalta = (rowData) => {
    if (rowData.id != 1) {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    }
  };

  const eliminarFalta = (rowData: Falta) => {
    if (rowData.id != 1) {
      return (
        <>
          <Button
            icon="pi pi-trash"
            rounded
            onClick={() => confirm1(rowData)}
            className="pencil"
            size="small"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const confirm1 = (rowData) => {
    setSelectedUser(rowData.id);
    setSelectedRow(rowData.id_chefe);

    confirmDialog({
      message: "Tem a certeza que pretende eliminar?",
    });
  };

  const editDiasCompletosFooter = (
    <>
      <Button
        label="Adicionar"
        icon="pi pi-check"
        text
        onClick={addChefe}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editHorasFooter = (
    <>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        text
        onClick={updateChefe}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [selectedPermissao, setSelectedPermissao] = useState("0");
  const [selectedSuperChefe, setSelectedSuperChefe] = useState("0");

  const permissoesOptions = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "0" },
  ];
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id_chefe === selectedRow ? "highlight" : "";
  };

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            emptyMessage="Nenhum Chefe encontrado."
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
          >
            <Column
              body={editarFalta}
              style={{
                textAlign: "center",
              }}
            ></Column>

            <Column
              field="nome"
              header="Chefe"
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>
            <Column
              field="nome_chefe_delegado"
              header="Chefia delegada a"
              style={{ minWidth: "5rem" }}
            ></Column>

            <Column
              field="safpText"
              header="Permissões SAFP"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="superChefeText"
              header="Super chefe"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              body={eliminarFalta}
              style={{
                textAlign: "center",
              }}
            ></Column>
          </DataTable>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
          >
            <div className="field">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Trabalhador
              </label>
              <Dropdown
                value={selectedTrabalhador}
                onChange={(e) => setSelectedTrabalhador(e.value)}
                options={trabalhadores}
                optionLabel="trabalhador"
                className="w-full md:w-19rem"
              />
            </div>

            <div className="field">
              <label
                htmlFor="PermissoesSAFP"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Permissões SAFP
              </label>
              <Dropdown
                value={selectedPermissao}
                onChange={(e) => setSelectedPermissao(e.value)}
                options={permissoesOptions}
                className="w-full md:w-19rem"
              />
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <Dialog
            visible={editHorasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editHorasFooter}
            onHide={hideEditHoras}
          >
            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Chefe
              </label>
              <Dropdown
                value={selectedChefe}
                onChange={(e) => setSelectedChefe(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                placeholder="opcional"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Delegar Chefia a
              </label>
              <Dropdown
                value={selectedChefe2}
                onChange={(e) => setSelectedChefe2(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                placeholder="opcional"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="PermissoesSAFP"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Permissões SAFP
              </label>
              <Dropdown
                value={selectedPermissao}
                onChange={(e) => setSelectedPermissao(e.value)}
                options={permissoesOptions}
                className="w-full md:w-19rem"
              />
            </div>

            <div className="field">
              <label
                htmlFor="SuperChefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Super Chefe
              </label>
              <Dropdown
                value={selectedSuperChefe}
                onChange={(e) => setSelectedSuperChefe(e.value)}
                options={permissoesOptions}
                className="w-full md:w-19rem"
              />
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label="Cancelar"
                    text
                    onClick={(event) => {
                      hide(event);
                      setSelectedUser(null);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label="Sim"
                    text
                    onClick={(event) => {
                      deleteChefe(selectedRow);
                      hide(event);
                      setSelectedUser(null);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default ChefesSAFP;
