import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import TopBar from "./components/TopBar";
import User from "./pages/Utilizador";
import Trabalhadores from "./pages/Trabalhadores";
import ChefesSAFP from "./pages/ChefesSAFP";
import Turnos from "./pages/Turnos";
import HorarioDeRefeicao from "./pages/HorarioDeRefeicao";
import Feriados from "./pages/Feriados";
import ChefiaPendentes from "./pages/ChefiaPendentes";
import ChefiaHistorico from "./pages/ChefiaHistorico";
import SAFPpendentes from "./pages/SAFPpendentes";
import SAFPhistorico from "./pages/SAFPhistorico";
import FeriasTrabalhadores from "./pages/FeriasTrabalhadores";
import DelegarChefia from "./pages/DelegarChefia";
import AlterarSenha from "./pages/AlterarSenha";
import RecuperarSenha from "./pages/RecuperarSenha";
import jwtDecode from "jwt-decode";

//import Parcelas from "./pages/ParcelasPage";
//import Intervencoes from "./pages/IntervencoesPage";
import Footer from "./components/Footer";
import LoginPage from "./pages/Login";
import "./index.css";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const AuthorizedRoute = ({ element: Component, allowedRoles }) => {
  const token = localStorage.getItem("jwtToken");

  if (!token) {
    return <Navigate to="/login" />;
  }

  const userRole = jwtDecode(token).role;

  if (allowedRoles.includes(userRole)) {
    return <Component />;
  } else {
    return <Navigate to="/login" />;
  }
};

AuthorizedRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.number).isRequired,
};

function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isRecuperarSenha = location.pathname === "/RecuperarSenha";

  return (
    <div className="min-h-screen flex flex-column surface-ground">
      {!isLoginPage && !isRecuperarSenha && <TopBar key={location.pathname} />}

      <div className="flex flex-column flex-auto justify-center items-center">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/RecuperarSenha" element={<RecuperarSenha />} />
          <Route
            path="/faltas"
            element={
              <AuthorizedRoute element={User} allowedRoles={[0, 1, 2]} />
            }
          />
          <Route
            path="/SAFPpendentes"
            element={
              <AuthorizedRoute element={SAFPpendentes} allowedRoles={[1]} />
            } // Admin only
          />
          <Route
            path="/SAFPhistorico"
            element={
              <AuthorizedRoute element={SAFPhistorico} allowedRoles={[1]} />
            }
          />
          <Route
            path="/Trabalhadores"
            element={
              <AuthorizedRoute element={Trabalhadores} allowedRoles={[1]} />
            }
          />
          <Route
            path="/ChefesSAFP"
            element={
              <AuthorizedRoute element={ChefesSAFP} allowedRoles={[1]} />
            }
          />
          <Route
            path="/Turnos"
            element={<AuthorizedRoute element={Turnos} allowedRoles={[1]} />}
          />
          <Route
            path="/HorarioDeRefeicao"
            element={
              <AuthorizedRoute element={HorarioDeRefeicao} allowedRoles={[1]} />
            }
          />
          <Route
            path="/Feriados"
            element={<AuthorizedRoute element={Feriados} allowedRoles={[1]} />}
          />
          <Route
            path="/ChefiaPendentes"
            element={
              <AuthorizedRoute
                element={ChefiaPendentes}
                allowedRoles={[0, 1]}
              />
            }
          />

          <Route
            path="/ChefiaHistorico"
            element={
              <AuthorizedRoute
                element={ChefiaHistorico}
                allowedRoles={[0, 1]}
              />
            }
          />

          <Route
            path="/FeriasTrabalhadores"
            element={
              <AuthorizedRoute
                element={FeriasTrabalhadores}
                allowedRoles={[0, 1]}
              />
            }
          />

          <Route
            path="/DelegarChefia"
            element={
              <AuthorizedRoute element={DelegarChefia} allowedRoles={[0, 1]} />
            }
          />

          {import.meta.env.VITE_API_VERSAO === "2" && (
            <Route
              path="/AlterarSenha"
              element={
                <AuthorizedRoute
                  element={AlterarSenha}
                  allowedRoles={[0, 1, 2]}
                />
              }
            />
          )}

          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>

      {!isLoginPage && !isRecuperarSenha && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
