/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { ConfirmDialog } from "primereact/confirmdialog";

import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
interface Trabalhador {
  id: number;
  trabalhador: string;
}
const Users = () => {
  const [falta, setFalta] = useState<Falta | null>(null);

  const [listaFaltas, setFaltas] = useState<Falta[]>([]);

  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    getFaltas();
    fetchUpcomingEventsData2();
    fetchUpcomingEventsData9();

    setFerias(
      generateNumbers().map((numero, index) => ({
        id: numero.id, // Se deseja que o id seja sequencial a partir de 0
        trabalhador: numero.trabalhador,
      }))
    );

    setMajoracao(
      generateNumbers2().map((numero, index) => ({
        id: numero.id, // Se deseja que o id seja sequencial a partir de 0
        trabalhador: numero.trabalhador,
      }))
    );
  }, []);

  const [ferias, setFerias] = useState<Trabalhador[]>([]);
  const [selectedFerias, setSelectedFerias] = useState<Trabalhador | null>(
    null
  );

  const [majoracao, setMajoracao] = useState<Trabalhador[]>([]);
  const [selectedMajoracao, setSelectedMajoracao] =
    useState<Trabalhador | null>(null);

  // Definindo uma função para gerar os números no intervalo desejado, incluindo os meio números
  const generateNumbers = (): { id: number; trabalhador: string }[] => {
    const numbers: { id: number; trabalhador: string }[] = [];

    // Loop para gerar os números inteiros e meio números
    for (let num = -150; num <= 150; num += 0.5) {
      numbers.push({ id: num, trabalhador: num.toString() });
    }

    return numbers;
  };

  const generateNumbers2 = (): { id: number; trabalhador: string }[] => {
    const numbers: { id: number; trabalhador: string }[] = [];

    // Loop para gerar os números inteiros e meio números
    for (let num = 0; num <= 3; num += 0.5) {
      numbers.push({ id: num, trabalhador: num.toString() });
    }

    return numbers;
  };

  const [errorMessage3, setErrorMessage3] = useState("");

  const getFaltas = async () => {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadores",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();
        if (data[0].error === "1") {
          setFaltas([]);
          setErrorMessage3("0 Trabalhadores.");
        } else {
          setFaltas(data);
          setErrorMessage3(data.length + " Trabalhadores.");
        }
      }
    } catch (error) {
      console.error("Could not getTrabalhadores", error);
    }
  };

  const [chefes, setChefes] = useState<Trabalhador[]>([]);
  const [selectedChefe, setSelectedChefe] = useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData9() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getChefes",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChefes(
          data.map((dado: { id_chefe: number; nome: string }) => ({
            id: dado.id_chefe,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [horarios, setHorarios] = useState<Trabalhador[]>([]);
  const [selectedHorario, setSelectedHorario] = useState<Trabalhador | null>(
    null
  );

  async function fetchUpcomingEventsData2() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getHorariosRefeicao",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setHorarios(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);
  const [editHorasDialog, setEditHorasDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const feriasInicial = {
    id: 0, // Converte para inteiro
    trabalhador: "0",
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const clean = () => {
    setNome(undefined);
    setUser(undefined);
    setEmail(undefined);
    setErrorMessage("");
    setSelectedChefe(null);
    setSelectedHorario(null);
    setSelectedFerias(feriasInicial);
    setSelectedMajoracao(feriasInicial);
    document.body.classList.add("no-scroll");
  };

  const openDiasCompletos = (rowData) => {
    handleVacationButtonClick();

    clean();

    setHeader("Trabalhador");
    setIcon("pi-user-plus");
    setEditDiasCompletosDialog(true);
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");

    setEmail(rowData?.email);
    setFalta(rowData);
    handleVacationButtonClick();
    setSelectedRow(rowData.ID);

    const chefe = {
      id: rowData.id_chefe, // Converte para inteiro
      trabalhador: rowData.nome_chefe,
    };
    setSelectedChefe(chefe);

    const horario = {
      id: rowData.id_refeicao, // Converte para inteiro
      trabalhador: rowData.refeicao,
    };
    setSelectedHorario(horario);

    setSelectedChefe(chefe);

    const ferias2 = {
      id: parseFloat(rowData.dias), // Converte para inteiro
      trabalhador: rowData.dias,
    };

    setSelectedFerias(ferias2);

    const majoracao = {
      id: parseFloat(rowData.majoracao), // Converte para inteiro
      trabalhador: rowData.majoracao,
    };

    setSelectedMajoracao(majoracao);
    setSelectedAtivo(rowData.id_ativo);
    setIcon("pi-user-edit");
    setHeader(rowData.nome);
    setErrorMessage("");
    setEditHorasDialog(true);
  };

  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const hideEditHoras = () => {
    setEditHorasDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const [IsAddTrabalhador, setIsAddTrabalhador] = useState(false);

  const addTrabalhador = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAddTrabalhador) return;
    setIsAddTrabalhador(true);

    if (nome == undefined || nome == "" || nome.trim().length == 0) {
      setIsAddTrabalhador(false);
      setErrorMessage("Nome é obrigatório. ");
      return;
    }
    if (nome.length > 50) {
      setIsAddTrabalhador(false);
      setErrorMessage("Nome só pode ter um máximo de 50 caracteres. ");
      return;
    }

    if (user == undefined || user == "" || user.trim().length == 0) {
      setIsAddTrabalhador(false);
      setErrorMessage("Nome de utilizador é obrigatório. ");
      return;
    }

    if (user.length > 50) {
      setIsAddTrabalhador(false);
      setErrorMessage(
        "Nome de utilizador só pode ter um máximo de 50 caracteres. "
      );
      return;
    }

    if (email == undefined || email == "" || email.trim().length == 0) {
      setIsAddTrabalhador(false);
      setErrorMessage("Email é obrigatório. ");
      return;
    }

    if (email.length > 100) {
      setIsAddTrabalhador(false);
      setErrorMessage("Email só pode ter um máximo de 100 caracteres. ");
      return;
    }

    if (selectedChefe == null) {
      setIsAddTrabalhador(false);
      setErrorMessage("Selecione um chefe.");
      return;
    }

    if (selectedHorario == null) {
      setIsAddTrabalhador(false);
      setErrorMessage("Selecione um horário de refeição.");
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/addTrabalhador",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            nome: nome,
            username: user,
            id_chefe: selectedChefe?.id,
            email: email,
            id_refeicao: selectedHorario.id,
            dias: selectedFerias?.id,
            majoracao: selectedMajoracao?.id,
          }),
        }
      );

      if (response.ok) {
        getFaltas();
        showNiceMessage("Trabalhador adicionado com sucesso");
        hideEditDiasCompletos();
      } else {
        setErrorMessage(
          "Erro ao adicionar trabalhador. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao adicionar trabalhador. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsAddTrabalhador(false);
    }
  };
  const [IsUpdating, setIsUpdating] = useState(false);

  const updateTrabalhador = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsUpdating) return;
    setIsUpdating(true);

    if (email == undefined || email == "" || email.trim().length == 0) {
      setErrorMessage("Email é obrigatório ");
      setIsUpdating(false);
      return;
    }

    if (email.length > 100) {
      setIsUpdating(false);
      setErrorMessage("Email só pode ter um máximo de 100 caracteres ");
      return;
    }

    if (selectedChefe == null) {
      setIsUpdating(false);
      setErrorMessage("Selecione um chefe");
      return;
    }

    if (selectedHorario == null) {
      setIsUpdating(false);
      setErrorMessage("Selecione um horário de refeição");
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/updateTrabalhador",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            ID: selectedRow,
            email: email,
            id_chefe: selectedChefe?.id,
            id_refeicao: selectedHorario?.id,
            dias: selectedFerias?.id,
            majoracao: selectedMajoracao?.id,
            ativo: selectedAtivo,
          }),
        }
      );

      if (response.ok) {
        getFaltas();
        showNiceMessage("Trabalhador atualizado com sucesso");
        hideEditHoras();
      } else {
        setErrorMessage(
          "Erro ao editar trabalhador. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao editar trabalhador. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label="Adicionar Trabalhador"
            icon="pi pi-plus"
            rounded
            className=" mr-2"
            onClick={openDiasCompletos}
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const editarFalta = (rowData) => {
    {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    }
  };

  const editDiasCompletosFooter = (
    <>
      <Button
        label="Adicionar"
        icon="pi pi-check"
        text
        onClick={addTrabalhador}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editHorasFooter = (
    <>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        text
        onClick={updateTrabalhador}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [selectedAtivo, setSelectedAtivo] = useState("1");

  const ativoOptions = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "0" },
  ];

  const [email, setEmail] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [user, setUser] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [nome, setNome] = useState<string | undefined>(undefined); // Estado para armazenar a descrição

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.ID === selectedRow ? "highlight" : "";
  };

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className=" botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>
          {errorMessage3 && (
            <p
              id="erro3"
              style={{
                paddingLeft: "0.8rem",
                color: import.meta.env.VITE_API_COLOR,
              }}
            >
              {errorMessage3}
            </p>
          )}
          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            emptyMessage="Nenhum Trabalhador encontrado."
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
          >
            <Column
              body={editarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              field="nome"
              header="Nome"
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>
            <Column
              field="username"
              header="Utilizador"
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="nome_chefe"
              header="Chefe"
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="refeicao"
              header="Refeição"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dias"
              header="Total Férias"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="majoracao"
              header="Majoração"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="gozados"
              header="Gozados"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              header="Restantes"
              style={{ minWidth: "4rem" }}
              body={(rowData) => {
                const restantes = rowData.dias - rowData.gozados;
                return restantes;
              }}
            ></Column>
            <Column
              field="pendentes"
              header="Pendentes"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="ativo"
              header="Ativo"
              style={{ minWidth: "4rem" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
          >
            <div className="field">
              <label htmlFor="nome" style={{ fontSize: "0.9rem" }}>
                Nome Próprio
              </label>
              <InputText
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="user" style={{ fontSize: "0.9rem" }}>
                Nome de Utilizador
              </label>
              <InputText
                id="nome"
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
            </div>

            <div className="field">
              <label htmlFor="email" style={{ fontSize: "0.9rem" }}>
                Email
              </label>
              <InputText
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Chefe
              </label>
              <Dropdown
                value={selectedChefe}
                onChange={(e) => setSelectedChefe(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Horario"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Horário de Refeição
              </label>
              <Dropdown
                value={selectedHorario}
                onChange={(e) => setSelectedHorario(e.value)}
                options={horarios}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>
            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Total Dias de Férias 2024
              </label>
              <Dropdown
                value={selectedFerias}
                onChange={(e) => setSelectedFerias(e.value)}
                options={ferias}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="majoracao"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Dias de Majoração (já incluído nos dias totais)
              </label>
              <Dropdown
                value={selectedMajoracao}
                onChange={(e) => setSelectedMajoracao(e.value)}
                options={majoracao}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <Dialog
            visible={editHorasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editHorasFooter}
            onHide={hideEditHoras}
          >
            <div className="field">
              <label htmlFor="email" style={{ fontSize: "0.9rem" }}>
                Email
              </label>
              <InputText
                id="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Chefe
              </label>
              <Dropdown
                value={selectedChefe}
                onChange={(e) => setSelectedChefe(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Horario"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Horário de Refeição
              </label>
              <Dropdown
                value={selectedHorario}
                onChange={(e) => setSelectedHorario(e.value)}
                options={horarios}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="ferias"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Total Dias de Férias 2024
              </label>
              <Dropdown
                value={selectedFerias}
                onChange={(e) => setSelectedFerias(e.value)}
                options={ferias}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="majoracao"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Dias de Majoração (já incluído nos dias totais)
              </label>
              <Dropdown
                value={selectedMajoracao}
                onChange={(e) => setSelectedMajoracao(e.value)}
                options={majoracao}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="Ativo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Ativo
              </label>
              <Dropdown
                value={selectedAtivo}
                onChange={(e) => setSelectedAtivo(e.value)}
                options={ativoOptions}
                className="w-full md:w-19rem"
              />
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil"
                    label="Cancelar"
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                      color: "#df8f34",
                    }}
                  ></Button>
                  <Button
                    label="Sim"
                    text
                    onClick={(event) => {
                      hide(event);
                      // accept();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default Users;
