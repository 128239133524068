/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { locale } from "primereact/api";
import { Calendar } from "primereact/calendar";

import { ConfirmDialog } from "primereact/confirmdialog";
import saveAs from "file-saver";

import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";

interface Motivo {
  id: number;
  motivo: string;
}

interface Trabalhador {
  id: number;
  trabalhador: string;
}

interface Despacho {
  id: number;
  despacho: string;
}

/* @todo Used 'as any' for types here. Will fix in next version due to onSelectionChange event type issue. */
const ChefiaPendentes = () => {
  // Aplicando a localidade globalmente
  locale("pt");

  const [textoChefia, settextoChefia] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  const [ferias, setFerias] = useState<Ferias[]>([]);

  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const [errorMessage3, setErrorMessage3] = useState("");

  const jwtToken = localStorage.getItem("jwtToken");
  const xsrfToken = localStorage.getItem("XSRF-TOKEN");
  useEffect(() => {
    getPedidosChefia();
    fetchUpcomingEventsData4();
    fetchUpcomingEventsData6();
    fetchUpcomingEventsData8();
  }, []);
  const getPedidosChefia = async () => {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getPedidosChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();

        if (data[0].error === "1") {
          setFaltas([]);
          setErrorMessage3("Tem 0 faltas por validar.");
        } else {
          setFaltas(data);
          setErrorMessage3("Tem " + data.length + " faltas por validar.");
        }
      }
    } catch (error) {
      console.error("Could not getPedidosChefia", error);
    }
  };

  const [trabalhadores, setTrabalhadores] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador, setSelectedTrabalhador] =
    useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData8() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTrabalhadores(
          data.map((dado: { ID: number; nome: string }) => ({
            id: dado.ID,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const [selectedMotivo, setSelectedMotivo] = useState<Motivo | null>(null);

  async function fetchUpcomingEventsData4() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getMotivos",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setMotivos(
          data.map((dado: { id: number; motivo: string }) => ({
            id: dado.id,
            motivo: dado.motivo,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despachoHierarquia, setDespachoHierarquia] = useState<Despacho[]>([]);
  const [selectedDespachoHierarquia, setSelectedDespachoHierarquia] =
    useState<Despacho | null>(null);

  async function fetchUpcomingEventsData6() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosHierarquia ",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespachoHierarquia(
          data
            .slice(1, -1)
            .map((dado: { id: any; despacho: any }, index: number) => ({
              id: dado.id,
              despacho:
                index === 1 ? dado.despacho.toUpperCase() : dado.despacho,
            }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }
  const [IsAuth, setIsAuth] = useState(false);

  const decisaoChefe = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAuth) return;
    setIsAuth(true);

    let autorizacao = "";
    let id_pedido = "";
    let informacao = "";
    if (selectedRow !== null && selectedRow !== undefined) {
      id_pedido = selectedRow;
    } else {
      setIsAuth(false);
      return null;
    }
    if (
      selectedDespachoHierarquia !== null &&
      selectedDespachoHierarquia !== undefined &&
      selectedDespachoHierarquia.id > 1
    ) {
      autorizacao = selectedDespachoHierarquia.id.toString();
    } else {
      setIsAuth(false);
      setErrorMessage("Selecione uma decisão");
      return null;
    }

    if (observacao == undefined || observacao.length == 0) {
    } else if (observacao.length > 0) {
      informacao = observacao.substring(0, 100);
    } else {
      setIsAuth(false);
      return null;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/authChefia",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id_pedido, autorizacao, informacao }),
        }
      );

      if (response.ok) {
        getPedidosChefia();
        hideEditDiasCompletos();
        hideEditFerias();
        showNiceMessage("Decisão confirmada com sucesso");
      } else {
        setErrorMessage(
          "Erro ao delegar chefia. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao delegar chefia. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsAuth(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const [IsProcura, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcura) return;
    setIsProcuras(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getPedidosChefia2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            motivo: selectedMotivo?.id || "",
            trabalhador: selectedTrabalhador?.id || "",
            descricao: observacao?.substring(0, 100) || "",
            dataInicio: dateI ? formatDate(dateI) : "", // Check if dateI is defined before calling formatDate
            dataFim: dateF ? formatDate(dateF) : "", // Check if dateF is defined before calling formatDate
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
        setFirst(0);
        hideProcuras();
        showInfoMessage("Procura efetuada com sucesso.");
      } else {
        setErrorMessage(
          "Erro nas procuras. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro nas procuras. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsProcuras(false);
    }
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (id_pedido) => {
    if (isDownloading) return;
    setIsDownloading(true);

    if (!csrfToken || !token) {
      setIsDownloading(false);
      return;
    }

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/getFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
          "x-xsrf-token": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ faltaId: id_pedido }),
      });

      if (response.ok) {
        const contentDisposition = response.headers.get("content-disposition");
        let filename = "download";

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1].replace(/['"]/g, "");
          }
        }

        const blob = await response.blob();
        saveAs(blob, filename);
      } else {
        setErrorMessage(
          "Erro ao fazer download. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao fazer download. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  type Ferias = {
    id: string;
    dias: number;
    [key: string]: string | number;
  };

  type Falta = {
    id_pedido: number;
    id_motivo: number;
    motivo: string;
    estado: string;
    descricao: string;
    desp_Chefia: string;
    Inf_Desp_Hierarquia: string;
    Desp_SAFP: string;
    dataInicio: string;
    horaInicio: string;
    dataFim: string;
    horaFim: string;
    dias: string;
    dataPedido: string;
    ficheiro: number;
    data_safp: string;
    SAFP: string;
    chefeAutorizacao: string;
    dataAutorizacao: string;
    safp_observacoes: string;
    [key: string]: string | number;
  };

  type Ficheiro = {
    id: number;
    nome: string;
    [key: string]: string | number;
  };

  const [procuraDialog, setProcuraDialog] = useState(false);
  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);
  const [editFeriasDialog, setEditFeriasDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    document.body.classList.add("no-scroll");
    setSelectedMotivo(null);
    setSelectedTrabalhador(null);
    setSelectedDespachoHierarquia(null);
    setDateI(undefined);
    setDateF(undefined);
    setObservacao("");
    setErrorMessage("");
  };

  const openProcuras = () => {
    handleVacationButtonClick();
    clean();

    setProcuraDialog(true);
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setErrorMessage("");
    setObservacao("");
    setSelectedRow(rowData.id_pedido);
    setSelectedDespachoHierarquia(null);
    if (rowData.id_motivo == "1") {
      const userFerias = [
        {
          id: "Total Dias de Férias",
          dias: rowData.dias2024,
          diasAutorizada: "",
          novoCampo: "valor",
        },
        {
          id: "Gozados",
          dias: rowData.diasgozados2024,
          diasAutorizada:
            parseFloat(rowData.diasgozados2024) + parseFloat(rowData.dias),
          novoCampo: "valor",
        },
        {
          id: "Restantes",
          dias: rowData.dias2024 - rowData.diasgozados2024,
          diasAutorizada:
            parseFloat(rowData.dias2024) -
            parseFloat(rowData.diasgozados2024) -
            parseFloat(rowData.dias),
          novoCampo: "valor",
        },
        {
          id: "Pendentes",
          dias: rowData.diaspendentes2024,
          diasAutorizada:
            parseFloat(rowData.diaspendentes2024) - parseFloat(rowData.dias),
          novoCampo: "valor",
        },
      ];
      setFerias(userFerias);
      settextoChefia(rowData.dias);
      setIcon("pi-sun");
      setHeader(rowData.trabalhador);
      setEditFeriasDialog(true);
    } else {
      setIcon("pi-calendar");

      setHeader(rowData.trabalhador);

      setEditDiasCompletosDialog(true);
    }
  };

  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const hideProcuras = () => {
    setProcuraDialog(false);
    close();
  };

  const hideEditFerias = () => {
    setEditFeriasDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(10); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };

  const reset = () => {
    setFirst(0);
    getPedidosChefia();
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2 buttons-container2   ">
          <Button
            label="Procuras"
            icon="pi pi-search
            "
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label="Limpar"
            icon="pi  pi-refresh
            "
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const downloadFileButton = (rowData) => {
    if (rowData.ficheiro === "1") {
      return (
        <>
          <Button
            icon="pi pi-download"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "#323232",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => downloadFile(rowData.id_pedido)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const editarFalta = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          size="small"
          className="pencil"
          rounded
          style={{
            background: "none",
            color: "#323232",
            border: "1px solid rgb(231, 238, 248)",
          }}
          onClick={() => editFalta(rowData)}
        />
      </>
    );
  };

  const editDiasCompletosFooter = (
    <>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        text
        onClick={decisaoChefe}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const editFeriasFooter = (
    <>
      <Button
        label="Confirmar"
        icon="pi pi-check"
        text
        onClick={decisaoChefe}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );
  const procuraDialogFooter = (
    <>
      <Button
        label="Pesquisar"
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.3rem", color: "#323232  " }}>Procuras</label>
    </div>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF, setDateF] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const [observacao, setObservacao] = useState<string | undefined>(undefined); // Estado para armazenar a descrição

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id_pedido === selectedRow ? "highlight" : "";
  };

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className=" botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          {errorMessage3 && (
            <p
              id="erro3"
              style={{
                paddingLeft: "0.8rem",
                color: import.meta.env.VITE_API_COLOR,
              }}
            >
              {errorMessage3}
            </p>
          )}

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={rows}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            emptyMessage="Nenhuma Falta encontrada."
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            <Column
              body={downloadFileButton}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
            <Column
              body={editarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              field="trabalhador"
              header="Trabalhador"
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="motivo"
              header="Motivo"
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="descricao"
              header="Descrição"
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="dataInicio"
              header="Data Início"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header="Hora Início"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dataFim"
              header="Data Fim"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaFim"
              header="Hora Fim"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dias"
              header="Tempo"
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dataPedido"
              header="Data do Pedido"
              style={{ minWidth: "4rem" }}
            ></Column>
          </DataTable>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
          >
            <div className="field ">
              <label htmlFor="Despacho" style={{ fontSize: "0.9rem" }}>
                Decisão sobre a Falta
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder="Escolha uma opção"
                className="w-full      md:w-19rem "
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                Informações adicionais
              </label>
              <InputText
                id="observacoes"
                placeholder="opcional"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
          </Dialog>

          <Dialog
            visible={editFeriasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editFeriasFooter}
            onHide={hideEditFerias}
          >
            <div className="field ">
              <label htmlFor="Despacho" style={{ fontSize: "0.9rem" }}>
                Decisão sobre as Férias
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder="Escolha uma opção"
                className="w-full   md:w-19rem "
              />
            </div>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                {errorMessage}
              </div>
            )}
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                Informações adicionais
              </label>
              <InputText
                id="observacoes"
                placeholder="opcional"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
            <DataTable
              ref={dt}
              value={ferias} // Passa a array 'ferias' diretamente para o 'value'
              className="datatable-responsive datatable-transition"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              emptyMessage="Nenhuma Falta encontrada."
              responsiveLayout="scroll"
              rowClassName={rowClassName}
              stripedRows
            >
              <Column
                field="id"
                header=""
                style={{
                  minWidth: "4rem",
                  padding: "1rem",
                }}
              ></Column>

              <Column
                field="dias"
                header="Atualmente"
                style={{ minWidth: "4rem" }}
              ></Column>
              <Column
                field="diasAutorizada"
                header="Caso seja Autorizada"
                style={{ minWidth: "4rem" }}
              ></Column>
            </DataTable>

            <div className="col-12">
              Dias das Férias: <strong>{textoChefia}</strong>
            </div>
          </Dialog>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
          >
            <div className="field ">
              <label
                htmlFor="Trabalhador"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Trabalhador
              </label>
              <Dropdown
                value={selectedTrabalhador}
                onChange={(e) => setSelectedTrabalhador(e.value)}
                options={trabalhadores}
                optionLabel="trabalhador"
                placeholder="opcional"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Motivo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Motivo
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                placeholder="opcional"
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="descricao"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Descrição
              </label>
              <InputText
                id="observacoes"
                placeholder="opcional"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataInicio"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Data de Início (a partir de)
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="opcional"
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataFim"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                Data de Fim (até a)
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
                placeholder="opcional"
              />
            </div>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil"
                    label="Cancelar"
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                      color: "#df8f34",
                    }}
                  ></Button>
                  <Button
                    label="Sim"
                    text
                    onClick={(event) => {
                      hide(event);
                      // accept();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default ChefiaPendentes;
