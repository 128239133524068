/* eslint-disable @next/next/no-img-element */
"use client";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import React, { useEffect, useRef, useState } from "react";

const Turnos = () => {
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);

  const jwtToken = localStorage.getItem("jwtToken");
  const xsrfToken = localStorage.getItem("XSRF-TOKEN");
  useEffect(() => {
    fetchUpcomingEventsData9();
  }, []);

  async function fetchUpcomingEventsData9() {
    try {
      if (jwtToken && xsrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": jwtToken,
              "x-xsrf-token": xsrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const dt = useRef<DataTable<any>>(null);

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: "100%" }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            emptyMessage="Nenhum Trabalhador encontrado."
            responsiveLayout="scroll"
            stripedRows
          >
            <Column
              field="nome"
              header="Trabalhador"
              style={{
                minWidth: "5rem",
              }}
            ></Column>
            <Column
              field="dias"
              header="Total Dias de Férias"
              style={{
                minWidth: "5rem",
              }}
            ></Column>
            <Column
              field="majoracao"
              header="Majoração (já incluído nos dias totais)"
              style={{
                minWidth: "5rem",
              }}
            ></Column>
            <Column
              field="gozados"
              header="Dias Gozados"
              style={{
                minWidth: "5rem",
              }}
            ></Column>
            <Column
              field="restantes"
              header="Dias Restantes"
              style={{ minWidth: "5rem" }}
              body={(rowData) => rowData.dias - rowData.gozados} // Adicionando a coluna calculada
            ></Column>
            <Column
              field="pendentes"
              header="Dias Pendentes"
              style={{ minWidth: "5rem" }}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Turnos;
