function Footer() {
  return (
    <div
      className="surface-section px-2 py-2 md:px-2 lg:px-2 text-center"
      style={{ border: "1px solid #e5e7eb", borderRadius: "25px" }}
    >
      <div className="font-medium text-900  "> &copy; 2024 Mind Solutions </div>
    </div>
  );
}

export default Footer;
