import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

import "../index.css";

function LoginPage() {
  const toast = useRef(null);
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const logoWidth = import.meta.env.VITE_API_WIDTH_LOGIN;
  const color = import.meta.env.VITE_API_LOGIN_COLOR;

  //const [errorMessage, setErrorMessage] = (useState < string) | (null > null);

  useEffect(() => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("XSRF-TOKEN");
    getCsrfToken();
  }, []);

  const getCsrfToken = async () => {
    try {
      const cookie = await axios.get(
        import.meta.env.VITE_API_URL + "/get-csrf-token",
        {
          withCredentials: true,
        }
      );
      localStorage.setItem("XSRF-TOKEN", cookie.data.csrfToken);
    } catch (error) {
      console.error("Could not get CSRF token", error);
      showError("Error. Contact IT department");
    }
  };

  const showError = (message) => {
    toast.current?.show({
      severity: "error",
      summary: "Login",
      detail: message,
      sticky: false,
      life: 5000,
    });
  };

  const clear = () => {
    if (toast.current) toast.current.clear();
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const usernameElement = document.getElementById("user");
    const username = usernameElement?.value;

    // Get the CSRF token from the cookie
    const csrfToken = localStorage.getItem("XSRF-TOKEN");

    if (!csrfToken) return;

    clear();

    try {
      const response = await fetch(import.meta.env.VITE_API_URL + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-xsrf-token": csrfToken, // Add CSRF token in the request header
        },
        credentials: "include",
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // Authentication successful
        const data = await response.json();
        const token = data.token;

        // Store the token securely (e.g., in local storage or cookies)
        localStorage.setItem("jwtToken", token);
        navigate("/faltas");

        // Redirect to authenticated pages or update the UI accordingly
        // You may use a router library like React Router for navigation
      } else {
        showError("Credenciais incorretas. Tente novamente.");
        setPassword(""); // Clear the password field
      }
    } catch (error) {
      showError("Ocorreu um erro ao tentar fazer login. Tente novamente.");
      console.error("An error occurred:", error);
    }
  };
  return (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "50%" }}
      >
        <div className="col-md-3">
          <div className="text-center">
            <img
              src="/images/logoG.png"
              alt="logo"
              style={{
                marginBottom: "2em",
                marginTop: "4em",
                width: logoWidth,
              }}
            />
          </div>
          {/*    {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}{" "} */}
          {/* <-- Add this line */}
          <form onSubmit={handleLogin}>
            <div
              className="form-group"
              style={{
                marginBottom: "0em",
              }}
            >
              <label htmlFor="User"> </label>
              <input
                type="text"
                id="user"
                name="user"
                className="form-control"
                placeholder="Nome de Utilizador"
                style={{
                  marginBottom: "0em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password"> </label>
              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder="Palavra-passe"
                value={password}
                style={{
                  marginBottom: "2em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-outline-dark btn-block "
              style={{
                fontSize: "16px", // Match the input field font size
                fontWeight: "400", // Adjust weight as needed
                padding: "5px 0", // Adjust padding as needed
                borderRadius: "20px", // Match the input field border radius
                width: "100%",
                boxSizing: "border-box", // Include padding and border in the width and height calculation
                color: color, // Definindo a cor do texto como laranja
                backgroundColor: "#fff", // Definindo a cor de fundo como branco
                borderColor: color,
              }}
            >
              Login
            </button>
            {import.meta.env.VITE_API_VERSAO === "2" && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <button
                  className="btn-link"
                  onClick={() => navigate("/RecuperarSenha")}
                  style={{
                    color: "#f5862c",
                    fontSize: "13px",
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Não sabe a sua palavra-passe?
                </button>
              </div>
            )}
          </form>
          <Toast ref={toast} position="top-center" />
        </div>
      </div>
    </div>
  );
  /* <div className="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round lg:w-6">
        <div className="text-center mb-5">
          <img
            src="/images/logoG.png"
            alt="hyper"
            height={20}
            className="mb-3"
          />
        </div>

        <div>
          <form onSubmit={handleLogin} className="login-form">
            <label htmlFor="user" className="block text-900 font-medium mb-2">
              Utilizador
            </label>
            <InputText
              id="user"
              type="text"
              className="w-full mb-3 custom-input"
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mb-3"
            />

            <div className="flex align-items-center justify-content-between mb-6">
              <div className="flex align-items-center"></div>
              <a className="font-medium no-underline ml-2 text-orange-700 text-right cursor-pointer">
                Esqueceu a sua password?
              </a>
            </div>

            <Button
              label="Login"
              icon="pi pi-user"
              className="w-full login-button"
            />
          </form>
          <Toast ref={toast} position="top-center" />
        </div>
      </div>
    </div> */
}

export default LoginPage;
