/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";

const Feriados = () => {
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    fetchUpcomingEventsData9();
  }, []);

  const toast = useRef<Toast>(null);

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showNiceMessage2 = (message) => {
    toast.current?.show({
      severity: "success",
      icon: "pi pi-trash",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  async function fetchUpcomingEventsData9() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getFeriados",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }
  const [IsDeleting, setIsDeleting] = useState(false);

  const deleteFeriado = async (id_feriado) => {
    if (!csrfToken) return;
    if (!token) return;

    if (id_feriado == null || id_feriado == undefined) return;

    if (IsDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/deleteFeriado",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id_feriado }),
        }
      );
      if (response.ok) {
        showNiceMessage2(`Feriado eliminado com sucesso.`);
      }

      fetchUpcomingEventsData9();
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  function isWeekend(dateString: string | number | Date): boolean {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    return dayOfWeek === 0 || dayOfWeek === 6;
  }
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const [IsAdding, setIsAdding] = useState(false);

  const addFeriado = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAdding) return;
    setIsAdding(true);

    if (dateI !== undefined && isWeekend(dateI)) {
      setIsAdding(false);
      setErrorMessage("Data do Feriado não pode ser fim-de-semana");
      return;
    }

    let foundDuplicate = listaFaltas.some((event) => {
      const dataFeriado1 = formatDate(dateI).split("-");
      const dataFeriado2 = `${dataFeriado1[2]}/${
        dataFeriado1[1]
      }/${dataFeriado1[0].substring(0, 4)}`;

      if (dataFeriado2 === event.dataFeriado) {
        setErrorMessage("Essa data já é um feriado. Escolha outra data.");
        return true; // Encontrou um duplicado
      }

      return false;
    });

    if (foundDuplicate) {
      setIsAdding(false);
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/addFeriado",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            dataFeriado: formatDate(dateI),
            descricao: descricao ? descricao.substring(0, 100) : "",
          }),
        }
      );

      if (response.ok) {
        fetchUpcomingEventsData9();
        hideEditDiasCompletos();
        showNiceMessage(`Feriado adicionado com sucesso.`);
      } else {
        setErrorMessage(
          "Erro ao adicionar Feriado. Contacte o departamento de informática."
        );
      }
    } catch (error) {
      setErrorMessage(
        "Erro ao adicionar Feriado. Contacte o departamento de informática."
      );
      console.error("An error occurred:", error);
    } finally {
      setIsAdding(false);
    }
  };

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");
  const [descricao, setDescricao] = useState(""); // Estado para armazenar a descrição
  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setDescricao("");
    setDateI(undefined);
    setErrorMessage("");
    document.body.classList.add("no-scroll");
  };

  const openDiasCompletos = () => {
    handleVacationButtonClick();

    clean();

    setHeader("Feriado");
    setIcon(" pi-calendar-plus");
    setEditDiasCompletosDialog(true);
  };

  const hideEditDiasCompletos = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2   ">
          <Button
            label="Adicionar Feriado"
            icon="pi pi-plus"
            rounded
            className=" mr-2"
            onClick={openDiasCompletos}
            style={{
              padding: "0.25rem 1rem",
              borderRadius: "20px", // Match the input field border radius
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const eliminarFalta = (rowData: Falta) => {
    if (true) {
      return (
        <>
          <Button
            icon="pi pi-trash"
            rounded
            onClick={() => confirm1(rowData)}
            className="pencil"
            size="small"
            style={{
              background: "none",
              color: "black",
              border: "1px solid rgb(231, 238, 248)",
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const confirm1 = (rowData) => {
    setSelectedRow(rowData.id);

    confirmDialog({
      message: "Tem a certeza que pretende eliminar?",
    });
  };

  const editDiasCompletosFooter = (
    <>
      <Button
        label="Adicionar"
        icon="pi pi-check"
        text
        onClick={addFeriado}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id === selectedRow ? "highlight" : "";
  };

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            emptyMessage="Nenhum Feriado encontrado."
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
          >
            <Column body={eliminarFalta} style={{ minWidth: "4rem" }}></Column>
            <Column
              field="dataFeriado"
              style={{
                minWidth: "5rem",
              }}
              header="Feriado"
            ></Column>
            <Column
              field="descricao"
              style={{
                minWidth: "5rem",
              }}
              header="Descrição"
            ></Column>
          </DataTable>
          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={editDiasCompletosFooter}
            onHide={hideEditDiasCompletos}
          >
            <div className="field">
              <label htmlFor="dataFeriado" style={{ fontSize: "0.9rem" }}>
                Data do Feriado
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                Descrição
              </label>
              <InputText
                id="descricao"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </div>
            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label="Cancelar"
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label="Sim"
                    text
                    onClick={(event) => {
                      deleteFeriado(selectedRow);
                      hide(event);
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default Feriados;
